import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "dialogVisible-info" }
const _hoisted_2 = { class: "dialogVisible-content" }
const _hoisted_3 = { class: "input-message" }
const _hoisted_4 = { class: "input-phone" }
const _hoisted_5 = { class: "input-phone" }
const _hoisted_6 = {
  key: 1,
  class: "send-code again"
}
const _hoisted_7 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _ctx.dialogVisibleFlag,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.dialogVisibleFlag) = $event)),
    title: "",
    width: "500px",
    "before-close": _ctx.handleClose,
    "destroy-on-close": "",
    center: "",
    "show-close": false,
    onOpen: _ctx.open,
    onClose: _ctx.close
  }, {
    footer: _withCtx(() => [
      _createElementVNode("span", _hoisted_7, [
        _createVNode(_component_el_button, {
          class: "content-close",
          onClick: _ctx.close
        }, {
          default: _withCtx(() => _cache[7] || (_cache[7] = [
            _createTextVNode("取消")
          ])),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_el_button, {
          class: "content-confirm",
          loading: _ctx.loading,
          type: "primary",
          onClick: _ctx.confirm
        }, {
          default: _withCtx(() => _cache[8] || (_cache[8] = [
            _createTextVNode("确定")
          ])),
          _: 1
        }, 8, ["loading", "onClick"])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[6] || (_cache[6] = _createElementVNode("div", { class: "content-title" }, " 双重认证 ", -1)),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("span", null, "+" + _toDisplayString(_ctx.areaCode), 1),
              _createVNode(_component_el_input, {
                modelValue: _ctx.phone,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.phone) = $event)),
                placeholder: "请输入手机号",
                readonly: true,
                type: "text"
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _cache[5] || (_cache[5] = _createElementVNode("div", { class: "img" }, null, -1)),
              _createVNode(_component_el_input, {
                modelValue: _ctx.code,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.code) = $event)),
                placeholder: "请输入验证码",
                type: "text",
                maxlength: "6",
                onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.code = String(_ctx.code).replace(/[^\d]/g, '')))
              }, null, 8, ["modelValue"]),
              (!_ctx.countFlag)
                ? (_openBlock(), _createElementBlock("span", {
                    key: 0,
                    class: "send-code",
                    onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.countDown && _ctx.countDown(...args)))
                  }, "发送验证码"))
                : _createCommentVNode("", true),
              (_ctx.countFlag)
                ? (_openBlock(), _createElementBlock("span", _hoisted_6, "重新获取 " + _toDisplayString(_ctx.countNum) + " S", 1))
                : _createCommentVNode("", true)
            ])
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "before-close", "onOpen", "onClose"]))
}