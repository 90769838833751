
import { computed, defineComponent, reactive, toRefs } from 'vue'
import { ElMessage } from 'element-plus'
import { useRouter } from 'vue-router'
import { useMutation } from '@vue/apollo-composable'
import * as gql from '@/graphql'
import storage, { StorageType } from '@/utils/storage'
import * as paramsWrap from '@/graphql/params-wrap'
import { DesensitizationType } from '@/utils/desensitization'

export default defineComponent({
  props: {
    dialogVisible: { type: Boolean, default: false },
    title: { type: String, default: '' },
    phoneMessage: { type: Object, default: () => ({}) }
  },
  emits: ['update:dialogVisible'],
  setup (props, { emit }) {
    const router = useRouter()
    const state = reactive({
      phone: '',
      areaCode: '',
      code: '',
      countFlag: false,
      countNum: 60,
      username: '',
      password: '',
      loading: false
    })
    const open = () => {
      state.phone = props.phoneMessage.phone
      state.areaCode = props.phoneMessage.area_code
      state.username = props.phoneMessage.username
      state.password = props.phoneMessage.password
    }
    const dialogVisibleFlag = computed({
      get () {
        return props.dialogVisible
      },
      set (val) {
        emit('update:dialogVisible', val)
      }
    })
    const handleClose = () => {
      emit('update:dialogVisible', false)
    }
    const close = () => {
      emit('update:dialogVisible', false)
      state.code = ''
    }
    // 登录
    const { mutate: loginDirectFunc } = useMutation(
      gql.tradLoginManager,
      { clientId: 'dmz' }
    )
    // 获取用户信息
    // const { result: postInfo } = useQuery(gql.lpoaManagersBindInfoList)
    const confirm = () => {
      if (state.code === '') {
        ElMessage({ message: '请输入验证码', type: 'warning', customClass: 'custom-warning' })
        return
      }
      if (state.code.length < 6) {
        ElMessage({ message: '请输入正确的验证码', type: 'warning', customClass: 'custom-warning' })
        return
      }
      state.loading = true
      const obj = {
        ghUserid: state.username,
        // password: state.password,
        password: paramsWrap.wrapSign(state.password, {
          type: DesensitizationType.full
        }),
        codeType: 'checkUseridLoginSms',
        code: state.code
      }
      loginDirectFunc({ params: obj }).then((result: any) => {
        const { ghLogin } = result.data
        console.log(ghLogin)
        if (ghLogin.ok) {
          storage.rcSetItem(
            StorageType.local,
            'sessionId',
            ghLogin.data.sessionId
          )
          // 获取用户信息 ( 暂时取消 )
          // const list = useResult(postInfo, [], data => data)
          // console.log(list, 'listlistlistlistlist')
          storage.rcSetItem(
            StorageType.local,
            'userInfo',
            ghLogin.data.clientId
          )
          router.push({
            path: '/trade/bulkTrading'
          })
          state.loading = false
          ElMessage({ message: '登录成功', type: 'success', customClass: 'custom-success' })
        } else {
          state.loading = false
          ElMessage({ message: '请输入正确的验证码', type: 'error', customClass: 'custom-error' })
        }
      })
    }
    const { mutate: loginDirectFuncSend } = useMutation(gql.tradLoginManager, { clientId: 'dmz' })
    // 倒计时
    const countDown = () => {
      const obj = {
        ghUserid: state.username,
        password: paramsWrap.wrapSign(state.password, {
          type: DesensitizationType.full
        }),
        codeType: 'sendWebUseridLoginSms'
      }
      console.log(obj)
      loginDirectFuncSend({ params: obj }).then((result: any) => {
        const { ghLogin } = result.data
        console.log(ghLogin)
        if (ghLogin.reason.err === 35114) {
          ElMessage({ message: '短信验证码发送成功', type: 'success', customClass: 'custom-success' })
        }
      })
      // 更改btn状态
      state.countFlag = !state.countFlag
      // 设置倒计时
      // let timer: any
      const timer = setInterval(() => {
        if (state.countNum <= 0) {
          // 清除定时器
          clearInterval(timer)
          // 更改btn状态
          state.countFlag = !state.countFlag
          // 重置倒计时状态
          state.countNum = 60
        }
        state.countNum--
      }, 1000)
    }
    return {
      handleClose,
      confirm,
      close,
      dialogVisibleFlag,
      countDown,
      open,
      ...toRefs(state)
    }
  }
})
