
// import openWindow from '@/utils/openWindow'
import { defineComponent, reactive, toRefs } from 'vue'

export default defineComponent({
  setup () {
    const methods = reactive({
      wechatHandleClick: (param: string) => {
        console.log(param)
        // alert('handle ' + param + ' here')
        // const appid = 'xxxxx'
        // const redirect_uri = encodeURIComponent('xxx/redirect?redirect=' + window.location.origin + '/auth-redirect')
        // const url = 'https://open.weixin.qq.com/connect/qrconnect?appid=' + appid + '&redirect_uri=' + redirect_uri + '&response_type=code&scope=snsapi_login#wechat_redirect'
        // openWindow(url, 'wechat', 540, 540)
      },
      tencentHandleClick: (param: string) => {
        console.log(param)
        // alert('handle ' + param + ' here')
        // const client_id = 'xxxxx'
        // const redirect_uri = encodeURIComponent('xxx/redirect?redirect=' + window.location.origin + '/auth-redirect')
        // const url = 'https://graph.qq.com/oauth2.0/authorize?response_type=code&client_id=' + client_id + '&redirect_uri=' + redirect_uri
        // openWindow(url, 'tencent', 540, 540)
      }
    })
    return {
      ...toRefs(methods)
    }
  }
})
