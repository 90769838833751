
import { defineComponent, reactive, watch, ref, nextTick, toRefs } from 'vue'
import LangSelect from '@/components/lang_select/Index.vue'
import SocialSign from './components/SocialSignin.vue'
import { useRoute, LocationQuery, useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import areaCodeDrop from './components/areaCodeDrop.vue'
// import { useStore } from '@/store'
// import { UserActionTypes } from '@/store/modules/user/action-types'
import messagePop from './components/messagePop.vue'
import { useI18n } from 'vue-i18n'
import { useMutation } from '@vue/apollo-composable'
import * as gql from '@/graphql'
import { nanoid } from 'nanoid'
import storage, { StorageType } from '@/utils/storage'
// indexedb
import { baseClient } from '@/apollo-client'
export default defineComponent({
  components: {
    LangSelect,
    SocialSign,
    messagePop,
    areaCodeDrop
  },
  setup() {
    const router = useRouter()
    const { mutate: registerDeviceFunc, onDone: registerDeviceDone } =
      useMutation(gql.registerDevice, { clientId: 'dmz' })
    const deviceId = storage.rcGetItem(StorageType.local, 'deviceId')
    if (!deviceId) {
      const obj = {
        hwId: nanoid(),
        installId: nanoid(),
        versionName: 'web',
        versionCode: 1,
        platform: 'android'
      }
      registerDeviceFunc({ params: obj })
      registerDeviceDone((result) => {
        const { registerDevice } = result.data
        if (registerDevice.ok) {
          storage.rcSetItem(
            StorageType.local,
            'deviceId',
            registerDevice.data.deviceId
          )
        }
      })
    }
    const userNameRef = ref(null)
    const passwordRef = ref(null)
    const loginFormRef: any = ref(null)
    // const router = useRouter()
    const route = useRoute()
    // const store = useStore()
    const { t } = useI18n()
    const state = reactive({
      loginForm: {
        username: '',
        password: ''
      },
      maxlength: 11,
      areaCode: '86',
      typeFlag: false,
      inputFlag: false,
      areaCodeList: [],
      countNum: 60,
      countFlag: false,
      phoneMessage: {},
      passwordType: 'text',
      loading: false,
      showDialog: false,
      capsTooltip: false,
      redirect: '',
      otherQuery: {},
      checked1: false,
      codeStr: '',
      compliance: true, // 合规
      bg: {
        backgroundImage:
          'url(' + require('../../../assets/images/login/bg.png') + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
      },
      logo: require('../../../assets/images/login/logo.svg'),
      confirmPopupFlag: false,
      versionDb: '', // 存储库版本号
      userType: 1
    })
    const checkCode = (rule: any, value: any, callback: any) => {
      if (value.length < 6) {
        callback(new Error('请输入正确的电话'))
      } else {
        callback()
      }
    }
    const rules = reactive({
      username: [
        { required: true, message: '请输入手机号码', trigger: 'blur' }
      ],
      password: [
        // { required: true, message: '请输入短信验证码', trigger: 'blur' },
        // { pattern: /^[A-Za-z0-9.]+$/, message: '密码格式不正确' }
        {
          required: true,
          validator: checkCode,
          max: 6,
          message: '请输入6位手机短信验证码',
          trigger: 'blur'
        }
      ]
    })
    const routerQuery = ref(route.query)
    // 获取区号
    const { mutate: getAreaCodeFunc } = useMutation(gql.getAreaCodes, {
      clientId: 'dmz'
    })
    getAreaCodeFunc({ params: { areaType: '', loginIp: '' } }).then(
      (res: any) => {
        console.log(res)
        const { getAreaCodes } = res.data
        state.areaCode = getAreaCodes.data.default.areaCode
        state.areaCodeList = getAreaCodes.data.options
      }
    )
    const getUserType = () => {
      baseClient
        .query({
          query: gql.userData,
          fetchPolicy: 'network-only'
        })
        .then((res) => {
          // const { userInfo } = res.data
          const { userData } = res.data
          if (userData.clientList[0].clientId) {
            state.userType = 3
            if (!userData.isPiVerify) {
              state.userType = 4
            } else {
              state.userType = 5
            }
          } else {
            state.userType = 2
          }
          // const userDataTemp = {
          //   ...userData,
          //   clientList: userInfo.clientList?.filter((item: any) => {
          //     return item.clientStatus === '0'
          //   })
          // }
          console.log('userDataTemp', userData)
          storage.rcSetItem(StorageType.local, 'userType', state.userType)
          storage.rcSetItem(StorageType.local, 'userData', userData)

          // 如果为投资移民账户，应该登录投资移民现金主账户，否则按原逻辑使用第一个账户
          const immigrantMainAccout = userData?.clientList?.find(
            (item: any) => {
              return item.clientType === 9 && item.fundAccountType === 3
            }
          )
          if (immigrantMainAccout) {
            storage.rcSetItem(
              StorageType.local,
              'currentClient',
              immigrantMainAccout
            )
            storage.rcSetItem(
              StorageType.local,
              'userName',
              immigrantMainAccout?.clientName
            )
            storage.rcSetItem(
              StorageType.local,
              'userInfo',
              immigrantMainAccout.clientId
            )
            // 这里设一个标记，用来判断是否是投资移民现金主账户，后续交易密码需要使用投资移民现金主账户传入
            storage.rcSetItem(
              StorageType.local,
              'immigrantMainAccout',
              immigrantMainAccout.clientId
            )
          } else {
            storage.rcSetItem(
              StorageType.local,
              'currentClient',
              userData?.clientList?.[0]
            )
            storage.rcSetItem(
              StorageType.local,
              'userName',
              userData?.clientList?.[0]?.clientName
            )
            storage.rcSetItem(
              StorageType.local,
              'userInfo',
              userData?.clientList?.[0]?.clientId
            )
            storage.rcRemoveItem(StorageType.local, 'immigrantMainAccout')
          }

          console.log('userType', state.userType)
          router.push({
            path: '/trade/bulkTrading'
          })
        })
    }
    // 登录 账户密码
    const { mutate: loginDirectFunc } = useMutation(gql.sendCodeF, {
      clientId: 'dmz'
    })
    const methods = reactive({
      checkCapslock: (e: KeyboardEvent) => {
        const { key } = e
        state.capsTooltip =
          key !== null && key?.length === 1 && key >= 'A' && key <= 'Z'
      },
      showPwd: () => {
        if (state.passwordType === 'password') {
          state.passwordType = ''
        } else {
          state.passwordType = 'password'
        }
        nextTick(() => {
          (passwordRef.value as any).focus()
        })
      },
      handleLogin: () => {
        // 登录的方法
        loginFormRef.value.validate(async (valid: boolean) => {
          console.log(valid)
          if (valid) {
            if (!state.compliance) {
              ElMessage({
                message:
                  '请先同意《个人资料收集声明》《流动程式使用条款》《免责声明》《私隐政策》和《电子交易条款》',
                type: 'warning',
                customClass: 'custom-warning'
              })
              return
            }
            // 发送请求
            state.loading = true
            const obj = {
              areaCode: state.areaCode,
              phone: state.loginForm.username,
              code: state.loginForm.password,
              // password: paramsWrap.wrapSign(state.loginForm.password, {
              //   type: DesensitizationType.full
              // }),
              codeType: 'checkPhoneLoginSms'
            }
            console.log(obj)
            loginDirectFunc({ params: obj }).then((res: any) => {
              const { ghLogin } = res.data
              console.log(ghLogin)
              if (ghLogin.ok) {
                storage.rcSetItem(
                  StorageType.local,
                  'sessionId',
                  ghLogin.data.sessionId
                )

                getUserType()

                state.loading = false
                ElMessage({
                  message: '登录成功',
                  type: 'success',
                  customClass: 'custom-success'
                })
              } else {
                state.loading = false
                ElMessage({
                  message: ghLogin.reason.desc,
                  type: 'error',
                  customClass: 'custom-error'
                })
              }
            })
          } else {
            return false
          }
        })
      }
    })
    function getOtherQuery(query: LocationQuery) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== 'redirect') {
          acc[cur] = query[cur]
        }
        return acc
      }, {} as LocationQuery)
    }
    // 选择了选项(下拉)
    const changeItem = (areaCode: any) => {
      // 选中了市价单 竞价市价单 隐藏价格 金额显示 - - 最大可买 - -
      state.loginForm.username = ''
      if (areaCode === '852') {
        state.maxlength = 8
      } else {
        state.maxlength = 11
      }
      state.areaCode = areaCode
      state.typeFlag = false
      state.inputFlag = false
    }
    // 选择区号
    const selectAreaCode = () => {
      console.log('点击了')
      state.typeFlag = true
      state.inputFlag = true
    }
    // 倒计时
    const countDown = () => {
      if (state.loginForm.username === '') {
        ElMessage({
          message: '请输入手机号码',
          type: 'warning',
          customClass: 'custom-warning'
        })
        return
      }
      const captcha = new window.TencentCaptcha(
        process.env.VUE_APP_H5_TENCENT,
        function (res: any) {
          if (res.ret === 0) {
            console.log(res)
            // 页面上滑动正确，请求自己的业务接口
            // 记得把验证成功的票据和随机字符带到自己接口中去腾讯验证票据的真实性
            state.codeStr = res.ticket + ',' + res.randstr
            const obj = {
              areaCode: state.areaCode,
              phone: state.loginForm.username,
              code: state.codeStr,
              // password: paramsWrap.wrapSign(state.loginForm.password, {
              //   type: DesensitizationType.full
              // }),
              codeType: 'sendPhoneLoginSms'
            }
            console.log(obj)
            loginDirectFunc({ params: obj }).then((result: any) => {
              const { ghLogin } = result.data
              console.log(ghLogin)
              if (ghLogin.reason.err === 35114) {
                ElMessage({
                  message: '短信验证码发送成功',
                  type: 'success',
                  customClass: 'custom-success'
                })
                return
              }
              ElMessage({
                message: ghLogin.reason.desc,
                type: 'error',
                customClass: 'custom-error'
              })
            })
            // 更改btn状态
            state.countFlag = !state.countFlag
            // 设置倒计时
            // let timer: any
            const timer = setInterval(() => {
              if (state.countNum <= 0) {
                // 清除定时器
                clearInterval(timer)
                // 更改btn状态
                state.countFlag = !state.countFlag
                // 重置倒计时状态
                state.countNum = 60
              }
              state.countNum--
            }, 1000)
          }
        }
      )
      captcha.langFun()
      // 滑块显示
      captcha.show()
    }
    watch(
      () => routerQuery,
      (query: any) => {
        if (query) {
          console.log(query)
          state.redirect = query.redirect?.toString() ?? ''
          state.otherQuery = getOtherQuery(query)
        }
      }
    )
    return {
      userNameRef,
      passwordRef,
      loginFormRef,
      ...toRefs(state),
      ...toRefs(methods),
      selectAreaCode,
      countDown,
      changeItem,
      t,
      rules
    }
  }
})
