import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, createBlock as _createBlock, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle } from "vue"
import _imports_0 from '@/assets/images/login/bg-logo.png'
import _imports_1 from '@/assets/images/login/down.png'


const _hoisted_1 = { class: "title-container" }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 1,
  class: "send-code again"
}
const _hoisted_4 = { class: "compliance" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_areaCodeDrop = _resolveComponent("areaCodeDrop")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_SocialSign = _resolveComponent("SocialSign")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _component_messagePop = _resolveComponent("messagePop")!

  return (_openBlock(), _createElementBlock("div", {
    class: "login-container",
    style: _normalizeStyle(_ctx.bg)
  }, [
    _cache[25] || (_cache[25] = _createElementVNode("img", {
      class: "bg-logo",
      src: _imports_0,
      alt: ""
    }, null, -1)),
    _createVNode(_component_el_form, {
      ref: "loginFormRef",
      model: _ctx.loginForm,
      rules: _ctx.rules,
      class: "login-form",
      autocomplete: "on",
      "label-position": "left"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("img", {
            src: _ctx.logo,
            alt: ""
          }, null, 8, _hoisted_2)
        ]),
        _cache[20] || (_cache[20] = _createElementVNode("div", { class: "login-title" }, "登录", -1)),
        _createVNode(_component_el_form_item, { prop: "username" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              ref: "userNameRef",
              modelValue: _ctx.loginForm.username,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.loginForm.username) = $event)),
              placeholder: "请输入手机号码",
              name: "username",
              maxlength: _ctx.maxlength,
              type: "text",
              tabindex: "1"
            }, {
              prefix: _withCtx(() => [
                _createElementVNode("span", {
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.selectAreaCode && _ctx.selectAreaCode(...args))),
                  class: "svg-container area-code"
                }, [
                  _createTextVNode(" +" + _toDisplayString(_ctx.areaCode) + " ", 1),
                  _cache[11] || (_cache[11] = _createElementVNode("img", {
                    src: _imports_1,
                    alt: ""
                  }, null, -1))
                ])
              ]),
              _: 1
            }, 8, ["modelValue", "maxlength"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_tooltip, {
          modelValue: _ctx.capsTooltip,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.capsTooltip) = $event)),
          content: "Caps lock is On",
          placement: "right",
          manual: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, { prop: "password" }, {
              default: _withCtx(() => [
                _cache[12] || (_cache[12] = _createElementVNode("span", { class: "svg-container" }, null, -1)),
                (_openBlock(), _createBlock(_component_el_input, {
                  key: _ctx.passwordType,
                  ref: "passwordRef",
                  modelValue: _ctx.loginForm.password,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.loginForm.password) = $event)),
                  placeholder: "请输入验证码",
                  name: "password",
                  type: _ctx.passwordType,
                  tabindex: "2",
                  onKeyup: [
                    _ctx.checkCapslock,
                    _withKeys(_ctx.handleLogin, ["enter"])
                  ],
                  onBlur: _cache[4] || (_cache[4] = ($event: any) => (_ctx.capsTooltip = false)),
                  onInput: _cache[5] || (_cache[5] = ($event: any) => (
              _ctx.loginForm.password = String(_ctx.loginForm.password).replace(
                /[^\d]/g,
                ''
              )
            )),
                  maxlength: "6"
                }, {
                  suffix: _withCtx(() => [
                    (!_ctx.countFlag)
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 0,
                          id: "TencentCaptcha",
                          "data-biz-state": "data-biz-state",
                          class: "send-code",
                          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.countDown && _ctx.countDown(...args)))
                        }, "发送验证码"))
                      : _createCommentVNode("", true),
                    (_ctx.countFlag)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_3, "重新获取 " + _toDisplayString(_ctx.countNum) + " S", 1))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["modelValue", "type", "onKeyup"]))
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _cache[21] || (_cache[21] = _createElementVNode("div", { class: "rem-psd" }, null, -1)),
        _createVNode(_component_el_button, {
          class: "login-btn",
          loading: _ctx.loading,
          type: "primary",
          style: {"width":"100%","margin-bottom":"30px"},
          onClick: _withModifiers(_ctx.handleLogin, ["prevent"])
        }, {
          default: _withCtx(() => _cache[13] || (_cache[13] = [
            _createTextVNode(" 登录 ")
          ])),
          _: 1
        }, 8, ["loading", "onClick"]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_el_checkbox, {
            class: "compliance-checkbox",
            modelValue: _ctx.compliance,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.compliance) = $event)),
            label: "阅读并同意",
            size: "large"
          }, null, 8, ["modelValue"]),
          _cache[14] || (_cache[14] = _createElementVNode("a", {
            class: "compliance-a",
            target: "_blank",
            href: "https://cdn.static.igoldhorse.cn/static_pages/login_person_info.html"
          }, "《个人资料收集声明》", -1)),
          _cache[15] || (_cache[15] = _createElementVNode("a", {
            class: "compliance-a",
            target: "_blank",
            href: "https://cdn.static.igoldhorse.cn/static_pages/login_application.html"
          }, "《流动程式使用条款》", -1)),
          _cache[16] || (_cache[16] = _createElementVNode("a", {
            class: "compliance-a",
            target: "_blank",
            href: "https://cdn.static.igoldhorse.cn/static_pages/login_disclaimer.html"
          }, "《免责声明》", -1)),
          _cache[17] || (_cache[17] = _createElementVNode("a", {
            class: "compliance-a",
            target: "_blank",
            href: "https://cdn.static.igoldhorse.cn/static_pages/login_privacy.html"
          }, "《私隐政策》", -1)),
          _cache[18] || (_cache[18] = _createTextVNode("和")),
          _cache[19] || (_cache[19] = _createElementVNode("a", {
            class: "compliance-a",
            target: "_blank",
            href: "https://cdn.static.igoldhorse.cn/static_pages/electronic_transaction.html"
          }, "《电子交易条款》", -1))
        ]),
        (_ctx.typeFlag && _ctx.inputFlag)
          ? (_openBlock(), _createBlock(_component_areaCodeDrop, {
              key: 0,
              onChangeItem: _ctx.changeItem,
              inputFlag: _ctx.inputFlag,
              "onUpdate:inputFlag": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.inputFlag) = $event)),
              list: _ctx.areaCodeList
            }, null, 8, ["onChangeItem", "inputFlag", "list"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["model", "rules"]),
    _createVNode(_component_el_dialog, {
      title: _ctx.t('login.thirdparty'),
      modelValue: _ctx.showDialog,
      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.showDialog) = $event))
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t("login.thirdpartyTips")) + " ", 1),
        _cache[22] || (_cache[22] = _createElementVNode("br", null, null, -1)),
        _cache[23] || (_cache[23] = _createElementVNode("br", null, null, -1)),
        _cache[24] || (_cache[24] = _createElementVNode("br", null, null, -1)),
        _createVNode(_component_SocialSign)
      ]),
      _: 1
    }, 8, ["title", "modelValue"]),
    _createVNode(_component_messagePop, {
      dialogVisible: _ctx.confirmPopupFlag,
      "onUpdate:dialogVisible": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.confirmPopupFlag) = $event)),
      phoneMessage: _ctx.phoneMessage
    }, null, 8, ["dialogVisible", "phoneMessage"])
  ], 4))
}