import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "social-signup-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "sign-btn",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.wechatHandleClick('wechat')))
    }, _cache[2] || (_cache[2] = [
      _createElementVNode("span", { class: "wx-svg-container" }, [
        _createElementVNode("svg", {
          class: "icon",
          "aria-hidden": "true"
        }, [
          _createElementVNode("use", { "xlink:href": "#iconwechat" })
        ])
      ], -1),
      _createElementVNode("span", null, " 微信 ", -1)
    ])),
    _createElementVNode("div", {
      class: "sign-btn",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.tencentHandleClick('tencent')))
    }, _cache[3] || (_cache[3] = [
      _createElementVNode("span", { class: "qq-svg-container" }, [
        _createElementVNode("svg", {
          class: "icon",
          "aria-hidden": "true"
        }, [
          _createElementVNode("use", { "xlink:href": "#iconalipay" })
        ])
      ], -1),
      _createElementVNode("span", null, " 支付宝 ", -1)
    ]))
  ]))
}