
import { defineComponent, reactive, toRefs } from 'vue'
export default defineComponent({
  components: {},
  props: {
    list: { type: Array, default: () => [] },
    inputFlag: { type: Boolean, default: false }
  },
  emits: ['changeItem', 'update:inputFlag'],
  setup (props, { emit }) {
    const state = reactive({})
    const changeItem = (areaCode: any) => {
      emit('changeItem', areaCode)
    }
    const inputFlagC = () => {
      emit('update:inputFlag', false)
    }
    return {
      ...toRefs(state),
      changeItem,
      inputFlagC
    }
  }
})
